import {extendTailwindMerge} from 'tailwind-merge';

import animation from './animation';
import backgroundImage from './backgroundImage';
import backgroundPosition from './backgroundPosition';
import boxShadow from './boxShadow';
import colors from './colors';
import content from './content';
import fontFamily from './fontFamily';
import fontSize from './fontSize';
import fontWeight from './fontWeight';
import gridTemplateColumns from './gridTemplateColumns';
import letterSpacing from './letterSpacing';
import maxWidth from './maxWidth';
import scale from './scale';
import spacing from './spacing';
import transitionTimingFunction from './transitionTimingFunction';
import zIndex from './zIndex';

const animationKeys = Object.keys(animation);
const backgroundImageKeys = Object.keys(backgroundImage);
const backgroundPositionKeys = Object.keys(backgroundPosition);
const boxShadowKeys = Object.keys(boxShadow);
const colorsKeys = Object.keys(colors);
const contentKeys = Object.keys(content);
const fontFamilyKeys = Object.keys(fontFamily);
const fontSizeKeys = Object.keys(fontSize);
const fontWeightKeys = Object.keys(fontWeight);
const gridTemplateColumnsKeys = Object.keys(gridTemplateColumns);
const letterSpacingKeys = Object.keys(letterSpacing);
const maxWidthKeys = Object.keys(maxWidth);
const scaleKeys = Object.keys(scale);
const spacingKeys = Object.keys(spacing);
const transitionTimingFunctionKeys = Object.keys(transitionTimingFunction);
const zIndexKeys = Object.keys(zIndex);

export const twMerge = extendTailwindMerge<string, string>({
  extend: {
    classGroups: {
      animate: [
        {
          animate: [...animationKeys],
        },
      ],
      'bg-image': [
        {
          bg: [...backgroundImageKeys],
        },
      ],
      'bg-position': [
        {
          'bg-position': [...backgroundPositionKeys],
        },
      ],
      content: [
        {
          content: [...contentKeys],
        },
      ],
      ease: [
        {
          ease: [...transitionTimingFunctionKeys],
        },
      ],
      'font-family': [
        {
          font: [...fontFamilyKeys],
        },
      ],
      'font-size': [
        {
          text: [...fontSizeKeys],
        },
      ],
      'font-weight': [
        {
          font: [...fontWeightKeys],
        },
      ],
      'grid-cols': [
        {
          'grid-cols': [...gridTemplateColumnsKeys],
        },
      ],
      'max-w': [
        {
          'max-w': [...maxWidthKeys],
        },
      ],
      shadow: [
        {
          shadow: [...boxShadowKeys],
        },
      ],
      tracking: [
        {
          tracking: [...letterSpacingKeys],
        },
      ],
      z: [
        {
          z: [...zIndexKeys],
        },
      ],
    },
    theme: {
      colors: [...colorsKeys],
      scale: [...scaleKeys],
      spacing: [...spacingKeys],
    },
  },
});
