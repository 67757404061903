import {useSiteData} from '@/hooks/useSiteData';
import env from '@/utils/env';

import {complianceZone, continentCode} from '../utils/server/countryCodes';

import useHandle from './useHandle';

interface DataLayer {
  affiliate?: string;
  blogCategory?: string;
  blogId?: string;
  complianceZone?: string;
  continentCode?: string;
  countryCode?: string;
  countryCurrencyCode?: string;
  regionCode?: string;
  displayCookiesNotice?: boolean;
  environment?: string;
  experimentVariationId?: string;
  httpCode?: string;
  language?: string;
  pageCategory?: string;
  pageGroup?: string;
  pageSubtopic?: string;
  pageTopic?: string;
}

export default function useDataLayer(httpCode = '200'): DataLayer {
  const {
    site,
    realCountryCode: code,
    regionCode,
    enPath,
    experimentVariationId,
  } = useSiteData();
  const {metadata} = useHandle();
  const realCountryCode = code || 'no_country';
  const [, pageTopic, pageSubtopic] = enPath?.split('/') || [];

  const meta = {
    affiliate: '',
    blogId: '',
    blogCategory: '',
    complianceZone: complianceZone(
      realCountryCode as string,
      regionCode as string,
    ),
    countryCode: realCountryCode,
    regionCode,
    continentCode: continentCode(realCountryCode as string),
    displayCookiesNotice: false,
    environment: env('SHOPIFY_APP_ENV'),
    experimentVariationId,
    language: site.locale,
    httpCode,
    pageSubtopic,
    pageTopic,
    ...(metadata || {}),
  };
  return meta;
}
