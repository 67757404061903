import React from 'react';
import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import type {Mode} from '@/components/base/types';

export const TEXT_SIZES: {
  [key: string]: string;
} = {
  dsp: 'text-dsp',
  t1: 'text-t1',
  t2: 'text-t2',
  t3: 'text-t3',
  t4: 'text-t4',
  t5: 'text-t5',
  t6: 'text-t6',
  t7: 'text-t7',
  t8: 'text-t8',
  p: 'text-body-base',
  'body-base': 'text-body-base',
  'body-sm': 'text-body-sm',
  'body-lg': 'text-body-lg',
};

export const typographyStyles = cva('', {
  variants: {
    isRichtext: {
      true: 'richtext',
      false: '',
    },
    mode: {
      light: 'text-black',
      dark: 'text-white',
      undefined: '',
    },
    size: TEXT_SIZES,
  },
  defaultVariants: {
    size: 'text-body-base',
    mode: 'undefined',
  },
});

export type TypographyOwnProps<E extends React.ElementType> = {
  as?: E;
  componentName?: string;
  isRichtext?: boolean;
  mode?: Mode;
  size?: (typeof TEXT_SIZES)[number];
};

export type TypographyProps<E extends React.ElementType> =
  React.PropsWithChildren<TypographyOwnProps<E>> &
    Omit<React.ComponentPropsWithoutRef<E>, keyof TypographyOwnProps<E>>;

export default function Typography<E extends React.ElementType = 'p'>({
  as,
  children,
  className,
  // No default componentName because it can be noisy in the DOM
  componentName,
  isRichtext = true,
  mode,
  size = 'body-base',
  ...restProps
}: TypographyProps<E>) {
  const Element = as || 'p';

  return isRichtext && typeof children !== 'object' ? (
    <Element
      className={twMerge(typographyStyles({isRichtext, mode, size}), className)}
      dangerouslySetInnerHTML={{__html: children as string}}
      data-component-name={componentName}
      {...restProps}
    />
  ) : (
    <Element
      className={twMerge(typographyStyles({isRichtext, mode, size}), className)}
      data-component-name={componentName}
      {...restProps}
    >
      {children}
    </Element>
  );
}
