// Client notes:
// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
// The "Unicode Problem"

export function base64Encode(str: string) {
  // Server uses Buffer API
  if (typeof document === 'undefined') {
    return Buffer.from(str).toString('base64');
  }
  // In most browsers, calling btoa() on a Unicode string will cause a Character Out Of Range exception.
  // First we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => {
      const charCode: number = parseInt(`0x${p1}`, 16);
      return String.fromCharCode(charCode);
    }),
  );
}

export function base64Decode(str: string) {
  // Server uses Buffer API
  if (typeof document === 'undefined') {
    return Buffer.from(str, 'base64').toString();
  }
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(
    atob(str)
      .split('')
      .map((chars) => `%${`00${chars.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
}

export function base64ToHex(str: string) {
  const raw = atob(str);
  let result = '';
  for (let i = 0; i < raw.length; i++) {
    const hex = raw.charCodeAt(i).toString(16);
    result += hex.length === 2 ? hex : '0' + hex;
  }
  return result.toLowerCase();
}
