export function FaviconLink({href}: {href: string}) {
  let type;
  if (href.endsWith('.png')) {
    type = 'image/png';
  } else if (href.endsWith('.ico')) {
    type = 'image/x-icon';
  } else if (href.endsWith('.svg')) {
    type = 'image/svg+xml';
  } else {
    type = 'image/x-icon';
  }

  return <link rel="icon" href={href} type={type} />;
}
