import {useEffect, useState} from 'react';

import {useLoadScript} from '@/hooks/useLoadScript';
import {FEATURE_FLAGS, SHOULD_LOG} from '@/constants';
import {useCookieNoticeBanner} from '@/hooks/useCookieNoticeBanner';

import type {DriftWindow} from './types';

const DRIFT_ID = 'gnrsmwa9432i';

const showDriftChat = (driftWindow: DriftWindow) => {
  const handleClick = (event: any) => {
    if (event.target.classList.contains('drift-open-chat')) {
      event.preventDefault();
      const interactionId = Number(event.target.dataset.interactionId);
      if (interactionId) {
        driftWindow.drift.api.startInteraction({interactionId});
      } else {
        driftWindow.drift.api.openChat();
      }
    }
  };

  document.body.addEventListener('click', handleClick);

  return function cleanUp() {
    document.body.removeEventListener('click', handleClick);
  };
};

const RenderDriftBot = () => {
  const [scriptSrc, setScriptSrc] = useState('');
  const {isConsentRequired, hasConsentedAll} = useCookieNoticeBanner();

  const scriptStatus = useLoadScript(scriptSrc);

  useEffect(() => {
    if (scriptStatus === 'done') {
      const driftWindow = window as DriftWindow;
      driftWindow.drift?.load(DRIFT_ID);
      showDriftChat(driftWindow);
    }
  }, [scriptStatus]);

  useEffect(() => {
    if (!isConsentRequired || hasConsentedAll) {
      setScriptSrc(`//js.driftt.com/include/1664493000000/${DRIFT_ID}.js`);
    }
  }, [isConsentRequired, hasConsentedAll]);
  return null;
};

export default function DriftBot() {
  // Default to false so that in cases where we don't
  // want to render Driftbot we aren't rendering it initially and then
  // removing it after the useEffect hook runs
  const [renderDriftBot, setRenderDriftBot] = useState(false);
  useEffect(() => {
    if (!FEATURE_FLAGS.DEBUG_DISABLE_ANALYTICS && !(window as any).E2E) {
      setRenderDriftBot(true);
    } else if (FEATURE_FLAGS.DEBUG_DISABLE_ANALYTICS && SHOULD_LOG) {
      // eslint-disable-next-line no-console
      console.log(
        `%c[debug][driftBot] disabled`,
        'background: #6940b7; color: #96bf48',
      );
    }
  }, [setRenderDriftBot]);

  return renderDriftBot ? <RenderDriftBot /> : null;
}
