export default {
  /* Base Colors */
  /* ======================================== */
  // Shopify green:
  'shopify-green-10': '#E5FBBA',
  'shopify-green-30': '#BCDF7B',
  'shopify-green-50': '#95BF47',
  'shopify-green-70': '#638622',
  'shopify-green-90': '#374D0F',
  // Lime:
  'lime-10': '#EEFAB3',
  'lime-30': '#D0F224',
  'lime-50': '#B0D00A',
  'lime-70': '#799102',
  'lime-90': '#4D5D01',
  'lime-100': '#ECFCCB',
  // Pistachio:
  'pistachio-10': '#D4F9E0',
  'pistachio-30': '#A5E3B9',
  'pistachio-50': '#419969',
  'pistachio-70': '#196A49',
  'pistachio-90': '#0A4D3B',
  // Aloe:
  'aloe-10': '#C1FBD4',
  'aloe-30': '#4BFE85',
  'aloe-50': '#22C357',
  'aloe-70': '#15883B',
  'aloe-90': '#0F4D24',
  // Avocado:
  'avocado-10': '#D5F2D8',
  'avocado-30': '#5BC766',
  'avocado-50': '#0F9C1D',
  'avocado-70': '#15751E',
  'avocado-90': '#1F4D23',
  // Jalapeño:
  'jalapeño-10': '#CCFFF2',
  'jalapeño-30': '#5FD5B6',
  'jalapeño-50': '#1CAA86',
  'jalapeño-70': '#008060',
  'jalapeño-90': '#074A38',
  // Kale:
  'kale-10': '#C7EDEC',
  'kale-30': '#84C6C5',
  'kale-50': '#479D9C',
  'kale-70': '#1E7372',
  'kale-90': '#054A49',
  // Currant
  'currant-10': '#C5D1FF',
  'currant-30': '#7D90E8',
  'currant-50': '#405EDD',
  'currant-70': '#1238BF',
  'currant-90': '#11215F',
  // Agave
  'agave-10': '#C6F0FC',
  'agave-30': '#79DFFF',
  'agave-50': '#299FC4',
  'agave-70': '#096A88',
  'agave-90': '#003B4D',
  // Lavender
  'lavender-10': '#CEC9F8',
  'lavender-30': '#978DE7',
  'lavender-50': '#6052DA',
  'lavender-70': '#3525C1',
  'lavender-90': '#1F1386',
  // Mandarin
  'mandarin-10': '#FCDECF',
  'mandarin-30': '#F6A179',
  'mandarin-50': '#ED6C31',
  'mandarin-70': '#9F4A22',
  'mandarin-90': '#4D3226',
  // Dragon Fruit
  'dragonfruit-10': '#FBCCFF',
  'dragonfruit-30': '#ED6BF8',
  'dragonfruit-50': '#B326BF',
  'dragonfruit-70': '#7C0B86',
  'dragonfruit-90': '#46034D',
  // Banana
  'banana-10': '#FCF3B0',
  'banana-30': '#F9E96E',
  'banana-50': '#F0D504',
  'banana-70': '#BFA900',
  'banana-90': '#877701',
  white: '#fff',
  'shade-05': '#FAFAFA',
  'shade-10': '#F4F4F5',
  'shade-20': '#E4E4E7',
  'shade-25': '#D2D5D9',
  'shade-30': '#D4D4D8',
  'shade-40': '#A1A1AA',
  'shade-50': '#71717A',
  'shade-60': '#52525B',
  'shade-70': '#3F3F46',
  'shade-80': '#27272A',
  'shade-90': '#18181B',
  'shade-95': '#0E0E10',
  'shade-100': '#000',
  black: '#000',

  /* UI State Colors */
  /* ======================================== */
  'state-focus': '#751BE9',
  'state-error': '#EE0004',
  'state-error-dark': '#EE0004',
  'state-error-light': '#FF6669',
  'state-success': '#15883B',
  'state-success-dark': '#15883B',
  'state-success-light': '#7AEB9F',
  'state-warning': '#E89900',
  'state-warning-dark': '#E89900',
  'state-warning-light': '#F1BE09',

  /* Utility Colors */
  /* ======================================== */
  current: 'currentColor',
  transparent: 'transparent',

  /* Element Colors */
  /* ======================================== */
  'link-light': '#000000',
  'link-light-hover': '#3F3F46',
  'link-light-active': '#71717A',
  'link-light-focus': '#000000',
  'link-light-disabled': '#A1A1AA',

  'link-dark': '#ffffff',
  'link-dark-hover': '#D4D4D8',
  'link-dark-active': '#A1A1AA',
  'link-dark-focus': '#ffffff',
  'link-dark-disabled': '#71717A',

  'badge-dark': '#ffffff',
  'badge-light': '#000000',

  /* Component Colors */
  /* ======================================== */
  'button-dark-primary-bg': '#ffffff',
  'button-dark-primary-bg-hover': '#d4d4d8',
  'button-dark-primary-bg-focus': '##ffffff',
  'button-dark-primary-bg-active': '#a1a1aa',
  'button-dark-primary-bg-disabled': '#52525b',

  'button-dark-primary-border': 'transparent',
  'button-dark-primary-border-hover': 'transparent',
  'button-dark-primary-border-focus': 'transparent',
  'button-dark-primary-border-active': 'transparent',
  'button-dark-primary-border-disabled': 'transparent',

  'button-dark-primary-text': '#000000',
  'button-dark-primary-text-hover': '#000000',
  'button-dark-primary-text-focus': '#000000',
  'button-dark-primary-text-active': '#000000',
  'button-dark-primary-text-disabled': '#71717a',

  'button-dark-secondary-bg': 'transparent',
  'button-dark-secondary-bg-hover': 'transparent',
  'button-dark-secondary-bg-focus': 'transparent',
  'button-dark-secondary-bg-active': 'transparent',
  'button-dark-secondary-bg-disabled': 'transparent',

  'button-dark-secondary-border': '#ffffff',
  'button-dark-secondary-border-hover': '#d4d4d8',
  'button-dark-secondary-border-focus': '#ffffff',
  'button-dark-secondary-border-active': '#a1a1aa',
  'button-dark-secondary-border-disabled': '#71717a',

  'button-dark-secondary-text': '#ffffff',
  'button-dark-secondary-text-hover': '#ffffff',
  'button-dark-secondary-text-focus': '#ffffff',
  'button-dark-secondary-text-active': '#ffffff',
  'button-dark-secondary-text-disabled': '#71717a',

  'button-light-primary-bg': '#000000',
  'button-light-primary-bg-hover': '#3f3f46',
  'button-light-primary-bg-focus': '#000000',
  'button-light-primary-bg-active': '#71717a',
  'button-light-primary-bg-disabled': '#d4d4d8',

  'button-light-primary-border': 'transparent',
  'button-light-primary-border-hover': 'transparent',
  'button-light-primary-border-focus': 'transparent',
  'button-light-primary-border-active': 'transparent',
  'button-light-primary-border-disabled': 'transparent',

  'button-light-primary-text': '#ffffff',
  'button-light-primary-text-hover': '#ffffff',
  'button-light-primary-text-focus': '#ffffff',
  'button-light-primary-text-active': '#ffffff',
  'button-light-primary-text-disabled': '#a1a1aa',

  'button-light-secondary-bg': 'transparent',
  'button-light-secondary-bg-hover': 'transparent',
  'button-light-secondary-bg-focus': 'transparent',
  'button-light-secondary-bg-active': 'transparent',
  'button-light-secondary-bg-disabled': 'transparent',

  'button-light-secondary-border': '#000000',
  'button-light-secondary-border-hover': '#3f3f46',
  'button-light-secondary-border-focus': '#000000',
  'button-light-secondary-border-active': '#71717a',
  'button-light-secondary-border-disabled': '#d4d4d8',

  'button-light-secondary-text': '#000000',
  'button-light-secondary-hover': '#000000',
  'button-light-secondary-focus': '#000000',
  'button-light-secondary-active': '#000000',
  'button-light-secondary-disabled': '#a1a1aa',

  'eyebrow-dark-text': '#ffffff',
  'eyebrow-light-text': '#000000',

  'highlight-light': '#3525C1',
  'highlight-dark': '#978DE7',

  'testimonial-light-highlight': '#3525C1',
  'testimonial-dark-highlight': '#978DE7',

  'fullwidthcard-dark-bg': '#000000',
  'fullwidthcard-dark-text': '#ffffff',
  'fullwidthcard-light-bg': '#ffffff',
  'fullwidthcard-light-text': '#000000',

  'section-dark-bg': '#000000',
  'section-dark-text': '#ffffff',
  'section-light-bg': '#ffffff',
  'section-light-text': '#000000',

  /* Legacy Colors */
  /* (unmigrated /sell/* pages only) */
  /* ======================================== */
  'legacy-pink-0': '#fff8f8',
  'legacy-pink-10': '#feecef',
  'legacy-pink-20': '#fedee2',
  'legacy-pink-30': '#fdc7ce',
  'legacy-pink-40': '#fca2b0',
  'legacy-pink-50': '#fb758e',
  'legacy-pink-60': '#e94e71',
  'legacy-pink-70': '#c43256',
  'legacy-pink-80': '#7d1c34',
  'legacy-pink-90': '#4f0e1f',
  'legacy-orange-0': '#fff8f6',
  'legacy-orange-10': '#ffede8',
  'legacy-orange-20': '#ffded4',
  'legacy-orange-30': '#ffc8b5',
  'legacy-orange-40': '#ffa781',
  'legacy-orange-50': '#f87e4a',
  'legacy-orange-60': '#da6322',
  'legacy-orange-70': '#b44f13',
  'legacy-orange-80': '#712e04',
  'legacy-orange-90': '#481a00',
  'legacy-yellow-0': '#fbf7ed',
  'legacy-yellow-10': '#ffefd2',
  'legacy-yellow-20': '#ffe2af',
  'legacy-yellow-30': '#ffcb67',
  'legacy-yellow-40': '#f4af28',
  'legacy-yellow-50': '#d2951d',
  'legacy-yellow-60': '#b07d12',
  'legacy-yellow-70': '#906509',
  'legacy-yellow-80': '#593d02',
  'legacy-yellow-90': '#372400',
  'legacy-green-0': '#f3fcf4',
  'legacy-green-10': '#dff7e5',
  'legacy-green-20': '#c1f0d0',
  'legacy-green-30': '#90e6b5',
  'legacy-green-40': '#69cf9c',
  'legacy-green-50': '#3fb484',
  'legacy-green-60': '#00996e',
  'legacy-green-70': '#008060',
  'legacy-green-80': '#004c3f',
  'legacy-green-90': '#002e25',
  'legacy-cyan-0': '#effcfa',
  'legacy-cyan-10': '#d6f8f3',
  'legacy-cyan-20': '#b9f0e9',
  'legacy-cyan-30': '#90dfd6',
  'legacy-cyan-40': '#67ccc3',
  'legacy-cyan-50': '#35b2aa',
  'legacy-cyan-60': '#25958f',
  'legacy-cyan-70': '#157a76',
  'legacy-cyan-80': '#054a49',
  'legacy-cyan-90': '#002d2d',
  'legacy-gray-0': '#fafafa',
  'legacy-gray-10': '#f0f1f2',
  'legacy-gray-20': '#e8e9eb',
  'legacy-gray-30': '#d2d5d9',
  'legacy-gray-40': '#b6babf',
  'legacy-gray-50': '#9fa5ab',
  'legacy-gray-60': '#828a91',
  'legacy-gray-70': '#6b7177',
  'legacy-gray-80': '#42474c',
  'legacy-gray-90': '#212326',
  'legacy-warm-gray-0': '#faf9f8',
  'legacy-warm-gray-10': '#f0efec',
  'legacy-warm-gray-20': '#e7e5e0',
  'legacy-warm-gray-30': '#d6d4ce',
  'legacy-warm-gray-40': '#bebbb4',
  'legacy-warm-gray-50': '#a3a099',
  'legacy-warm-gray-60': '#89867f',
  'legacy-warm-gray-70': '#6f6d66',
  'legacy-warm-gray-80': '#44423d',
  'legacy-warm-gray-90': '#292824',
  'legacy-white': '#ffffff',
  'legacy-black': '#000000',

  /* Page Colors */
  /* ======================================== */
  // capital
  'capital-red': '#4F0A25',

  // commerce
  'commerce-store-front': '#255DEE',
  'commerce-cart-checkout': '#1B1B1B',
  'commerce-shipping-logistics': '#D2E19F',
  'commerce-button-hover': '#0F44CC',
  'commerce-evergreen': '#253A23',
  'commerce-seafoam': '#E2FEFA',
  'commerce-oyster': '#D4F2EE',
  'commerce-gray-70': '#525252',
  'commerce-super-green': '#E1FF7C',
  'commerce-super-green-30': '#C6FE0A',

  // fulfillment
  'fulfillment-lavender': '#F3F1FF',
  'fulfillment-gray': '#71717A',
  'fulfillment-warning': '#E89900',

  // pos
  'pos-light': '#4D5349',
  'pos-dark': '#42473E',
  'pos-cyan-light': '#EEFAF9',
  'pos-warm-gray': '#F3F3EC',

  // starter
  'starter-green': '#45f298',
  'starter-blue': '#143db8',
  'starter-medium-green': '#004c3f',
  'starter-dark-green': '#002e25',
  'starter-chat-header': '#45D674',
  'starter-chat-window': '#DEECF9',
  'starter-outbound-message': '#E0F9D0',
  'starter-light-gray': '#EEEEEE',
  'starter-dark-gray': '#777777',

  // tax
  'tax-gray-lavender': '#EFEEF4',
  'tax-light-lavender': '#F7F6FE',

  // Editions-primary
  'editions-primary': '#2b54f1',

  // order
  'white-alpha-30': 'rgba(255, 255, 255, 0.3)',
  'white-alpha-70': 'rgba(255, 255, 255, 0.7)',

  // bng
  'bng-dark': '#1A0534',

  // tiktok
  'tiktok-red': '#fe2c55',
  'tiktok-blue': '#00F2EA',
};
