import {useSiteData} from '../Page/SiteData';

interface PreconnectHint {
  href: string;
  crossorigin?: '' | 'anonymous' | 'use-credentials' | undefined;
}

export default function PreconnectHints() {
  const preconnectHints: PreconnectHint[] = [];
  const {realCountryCode} = useSiteData();

  if (realCountryCode !== 'CN') {
    preconnectHints.push({href: 'https://cdn.shopify.com'});
  }

  preconnectHints.push(
    {href: 'https://gtm.shopify.com'},
    {href: 'https://www.googletagmanager.com'},
  );

  return (
    <>
      {preconnectHints.map((tag) => (
        <link
          href={tag.href}
          key={tag.href}
          rel="preconnect"
          crossOrigin={tag.crossorigin || undefined}
        />
      ))}
    </>
  );
}
