export default {
  marquee: 'marquee 60s linear infinite',
  'logo-group-marquee': 'logo-group-marquee 60s linear infinite',
  'logo-group-marquee-reverse':
    'logo-group-marquee-reverse 60s linear infinite',
  'fade-in-200ms-linear': 'fade-in 200ms linear forwards',
  'fade-in': 'fade-in 0.5s ease-in-out forwards',
  'fade-in-up': 'fade-in-up 0.5s ease-in-out forwards',
  'filters-fade-in': 'filters-fade-in 0.3s both',
  'filters-slide-in-right': 'filters-slide-in-right 0.3s both',
  'manage-header-gradient-1':
    'manage-header-gradient-1 4s infinite ease-in-out alternate',
  'manage-header-gradient-2':
    'manage-header-gradient-2 5s infinite ease-in-out alternate',
  'market-header-gradient-1':
    'market-header-gradient-1 4s infinite ease-in-out alternate',
  'market-header-gradient-2':
    'market-header-gradient-2 5s infinite ease-in-out alternate',
  'market-header-gradient-3':
    'market-header-gradient-3 6s infinite ease-in-out alternate',
  'sell-header-gradient-1':
    'sell-header-gradient-1 6s infinite ease-in-out alternate',
  'sell-header-gradient-2':
    'sell-header-gradient-2 5s infinite ease-in-out alternate',
  'sell-header-gradient-3':
    'sell-header-gradient-3 4s infinite ease-in-out alternate',
  'start-header-gradient-1':
    'start-header-gradient-1 4s infinite ease-in-out alternate',
  'start-header-gradient-2':
    'start-header-gradient-2 5s infinite ease-in-out alternate',
  'start-header-gradient-3':
    'start-header-gradient-3 6s infinite ease-in-out alternate',
  shine: 'shine 400ms ease-out 50ms 1',
  rise: 'rise 1s cubic-bezier(.35,.95,.67,.99) forwards',
  'homepage-image-tracks': 'homepage-hero-image-tracks 1s ease-out forwards',
  'homepage-merchant-testimonial':
    'homepage-testimonial-text-gradient 3s ease-in-out 1 forwards',
  'homepage-promo-bar-expand':
    'expand-full-width 0.65s cubic-bezier(0.76, 0.00, 0.24, 1.00) forwards',
  'homepage-promo-bar-content': 'fade-in 0.2s linear 0.45s forwards',
  'homepage-promo-bar-button': 'fade-in 0.2s linear 0.55s forwards',

  // tap-to-pay-iphone
  'move-phrase-in':
    '2.5s move-phrase-in calc((var(--index) * 2.5) * 1s) forwards',
  'move-phrase-in-last':
    '2.5s move-phrase-in-last calc((var(--index) * 2.5) * 1s) forwards',

  // careers
  arrowpoint: 'arrowpoint 1s linear infinite',
  scroll: 'scroll 6s linear infinite',

  // pos hero
  'pos-hero-counter':
    'pos-hero-counter cubic-bezier(0, 0.54, 0.32, 1.44) 1.6s forwards 0.5s',

  // plus
  'plus-hero-marquee': 'marquee 30s linear infinite',
  'plus-hero-gradient-1':
    'plus-hero-gradient-1 4s infinite ease-in-out alternate',
  'plus-hero-gradient-2':
    'plus-hero-gradient-2 5s infinite ease-in-out alternate',
  'plus-hero-gradient-3':
    'plus-hero-gradient-3 6s infinite ease-in-out alternate',

  // incentives promo banner
  'incentives-fill-background':
    'incentives-fill-background 750ms cubic-bezier(0.76, 0.00, 0.24, 1.00) 500ms forwards',

  // AppsShowcase
  'apps-showcase-slide':
    'apps-showcase-slide var(--transform-time) linear infinite',
  'apps-showcase-tooltip-fade-in-up':
    'apps-showcase-tooltip-fade-in-up 0.3s ease-out forwards',
};
