import {Suspense} from 'react';

import type {IconEnum} from '@/enums';
import useHydrated from '@/hooks/useHydrated';

import svgIconMappings from './svgIconMappings';

interface SvgIconProps {
  icon: IconEnum;
  className?: string;
  size: number;
}

export default function SvgIcon({
  className,
  icon,
  size,
  ...props
}: SvgIconProps) {
  const isHydrated = useHydrated();

  if (!isHydrated) {
    return null;
  }

  const Icon = svgIconMappings[icon];

  if (!Icon) {
    return null;
  }

  const fallbackPlaceholder = (
    <div
      style={{
        width: `${size}px`,
        height: `${size}px`,
        display: 'inline-block',
      }}
    />
  );

  return (
    <Suspense fallback={fallbackPlaceholder}>
      <Icon height={size} width={size} className={className} {...props} />
    </Suspense>
  );
}
