let cmsAssetsPath = 'https://cdn.shopify.com/b/shopify-brochure2-assets';

export default {
  'external-indicator': `url("https://cdn.shopify.com/shopifycloud/brochure2/production/images/cookie-banner/external-indicator.svg")`,

  // free-trail page
  // en - english
  'free-trial-conversion-last-before': `url("${cmsAssetsPath}/0d56efcd813bc72d0427f8863976c3a1.svgz")`,
  'free-trial-conversion-last-after': `url("${cmsAssetsPath}/1d5f94e0fb634025423acb965ece0b18.svgz")`,
  // de - german
  'free-trial-conversion-last-before-de': `url("${cmsAssetsPath}/58ad33e7c56cae06bed3edd69b9b5f0b.svgz")`,
  'free-trial-conversion-last-after-de': `url("${cmsAssetsPath}/c851c15868052e67acdb5f1f607ca682.svgz")`,
  // es - spanish
  'free-trial-conversion-last-before-es': `url("${cmsAssetsPath}/5aa0fb162b1de1a51ef43ebe55e611cf.svgz")`,
  'free-trial-conversion-last-after-es': `url("${cmsAssetsPath}/e43994bf27d773adb5c7765c1ffaaf40.svgz")`,
  // fr - french
  'free-trial-conversion-last-before-fr': `url("${cmsAssetsPath}/d6cc2f0568312600915c2227b2cfc642.svgz")`,
  'free-trial-conversion-last-after-fr': `url("${cmsAssetsPath}/a10fda52e5a9602125ef1cbd5f4a9322.svgz")`,
  // it - italian
  'free-trial-conversion-last-before-it': `url("${cmsAssetsPath}/7c429f293a86b8209b7ed28e26025441.svgz")`,
  'free-trial-conversion-last-after-it': `url("${cmsAssetsPath}/82381ad5093037ec5bc482bfe239eac4.svgz")`,
  // jp - japanese
  'free-trial-conversion-last-before-jp': `url("${cmsAssetsPath}/1f0ce1cd874842077d8f2f028ab514e7.svgz")`,
  'free-trial-conversion-last-after-jp': `url("${cmsAssetsPath}/bff3a9ada684e1cc8aa1b2acff92be9e.svgz")`,
  // pt-br - brazilian portuguese
  'free-trial-conversion-last-before-pt': `url("${cmsAssetsPath}/bfed3ad47edf5256be2e7ff3c3cad864.svgz")`,
  'free-trial-conversion-last-after-pt': `url("${cmsAssetsPath}/9bc662361179fa131b8cbd4c05887a19.svgz")`,

  'starter-arrow': `url("${cmsAssetsPath}/072b0901e9fc6bbb0e72dea552fdebc9.svgz")`,

  'accordion-expand': `url("${cmsAssetsPath}/80a4fb241c4267dd2e65f9648d5af2ba.svgz")`,
  'accordion-collapse': `url("${cmsAssetsPath}/fec744bb3c963a0aeba907847c22aac5.svgz")`,
  'caret-down': `url("${cmsAssetsPath}/a3e0a5b2c04244ae08cf7eab1947241d.svgz")`,
  'joblist-divide':
    'url(https://cdn.shopify.com/shopifycloud/brochure/assets/home/arrow-spin-17347dbae81a6a24bb6027068402e60f0ec76f0822926e2e7daa80108ff20c56.svg)',
};
