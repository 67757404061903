import {useEffect} from 'react';

import env from '@/utils/env';

export default function MetaHydrated() {
  useEffect(() => {
    if (env('SHOPIFY_ENABLE_HYDRATION_META_TAG') === 'true') {
      const meta = document.createElement('meta');
      meta.name = 'hydrated';
      meta.content = 'true';
      document.getElementsByTagName('head')[0].appendChild(meta);
    }
  }, []);

  return null;
}
