import type {SVGProps} from 'react';

const SvgPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12.0003C22 12.4529 21.7547 12.8707 21.3533 13.1021L8.02 20.8161C7.808 20.9383 7.57067 21 7.33333 21C7.10667 21 6.88 20.9447 6.676 20.8329C6.25867 20.604 6 20.1772 6 19.7143V4.28627C6 3.82343 6.25867 3.39659 6.676 3.16774C7.09333 2.9376 7.608 2.94532 8.02 3.18445L21.3533 10.8985C21.7547 11.1299 22 11.5477 22 12.0003Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPlay;
