import {useCallback} from 'react';
import {useDuxState} from '@shopify/dux';

import {buildSignupURL} from '@/utils/signup/url';

export function useSignupUrl() {
  const store = useDuxState((state) => state.store);

  const getSignupURL = useCallback(
    ({url, email}: {url: string; email?: string}) => {
      const pageViewToken = store?.pageViewToken;
      return buildSignupURL({url, email, pageViewToken});
    },
    [store?.pageViewToken],
  );

  return {getSignupURL};
}
