// UTM param primer https://vault.shopify.io/pages/6396-Tracking-and-Attribution
import {useEffect} from 'react';

import {
  addGclidFromRequestToUrl,
  addUtmParamsFromRequestToUrl,
} from '@/utils/utm/utm';

const SHOPIFY_ADMIN_HOST = 'admin.shopify.com';
const SHOPIFY_PARTNERS_HOST = 'partners.shopify.com';

interface UtmClickHandlerProps {
  requestQueryString: string | null;
}

export default function UtmClickHandler({
  requestQueryString,
}: UtmClickHandlerProps) {
  useEffect(() => {
    document.addEventListener(`click`, (event: MouseEvent) => {
      const link: any = event.target;
      const href = link.getAttribute(`href`);
      const newTab = link.getAttribute(`target`) === `_blank`;
      if (
        href &&
        (href.includes(SHOPIFY_ADMIN_HOST) ||
          href.includes(SHOPIFY_PARTNERS_HOST))
      ) {
        event.preventDefault();
        const adminUrlWithUtmParams = addUtmParamsFromRequestToUrl(
          href,
          requestQueryString,
        );
        const adminUrlWithGclid = addGclidFromRequestToUrl(
          adminUrlWithUtmParams,
          requestQueryString,
        );
        if (newTab) {
          window.open(adminUrlWithGclid, `_blank`);
        } else {
          window.location.href = adminUrlWithGclid;
        }
      }
    });
  }, [requestQueryString]);

  return null;
}
