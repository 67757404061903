import {useMatches, useRouteError} from '@remix-run/react';

import {Theme} from '@/enums';

function useTheme() {
  const error = useRouteError();
  const matches = useMatches();
  const match: any = [...matches]
    .reverse()
    .find(({handle}: any) => handle && handle.theme);
  const theme = match?.handle?.theme;
  const parentTheme = match?.handle?.parentTheme;
  const themes = [];

  if (theme) {
    themes.push(theme);
  }

  if (parentTheme) {
    themes.push(parentTheme);
  }

  if (error) {
    themes.push(Theme.BrochureV2);
  }
  return themes;
}

export default useTheme;
