import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import type {Mode} from '@/components/base/types';
import type {IconEnum} from '@/enums';

import type {BaseIconProps} from '../../types';

const collapseStyles = cva(
  'absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 transition-all duration-500 delay-[50ms]',
  {
    variants: {
      isExpanded: {
        true: 'opacity-100',
        false: 'opacity-0',
      },
      mode: {
        light: 'text-black',
        dark: 'text-white',
      },
    },
  },
);

const expandStyles = cva(
  'absolute inset-1/2 -translate-x-1/2 -translate-y-1/2 transition-all duration-500 delay-[50ms]',
  {
    variants: {
      isExpanded: {
        true: 'opacity-0',
        false: 'opacity-100',
      },
      mode: {
        light: 'text-white',
        dark: 'text-black',
      },
    },
  },
);

const expandHorizontalStyles = cva(
  'origin-center transition-transform duration-500 delay-[50ms]',
  {
    variants: {
      isExpanded: {
        true: 'rotate-180',
        false: 'rotate-0',
      },
    },
  },
);

const expandVerticalStyles = cva(
  'origin-center transition-transform duration-500 delay-[50ms]',
  {
    variants: {
      isExpanded: {
        true: 'rotate-90',
        false: 'rotate-0',
      },
    },
  },
);

export interface ExpandCollapseIconOptions {
  isExpanded?: boolean;
  mode?: Mode;
}

export interface ExpandCollapseIconProps extends BaseIconProps {
  icon: IconEnum.ExpandCollapse;
  options?: ExpandCollapseIconOptions;
}

type Props = Omit<ExpandCollapseIconProps, 'icon'>;

export default function ExpandCollapseIcon(props: Props) {
  return (
    <div className="relative" style={{width: props.size, height: props.size}}>
      <CollapseIcon {...props} />
      <ExpandIcon {...props} />
    </div>
  );
}

export function CollapseIcon({
  className,
  options = {isExpanded: false},
  size,
  style,
}: Props) {
  const {isExpanded, mode} = options;

  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      className={twMerge(collapseStyles({isExpanded, mode}), className)}
      style={{width: size, height: size, ...style}}
    >
      <rect x="4" y="9" width="12" height="2" fill="currentColor" />
    </svg>
  );
}

export function ExpandIcon({
  className,
  options = {isExpanded: false},
  size,
  style,
}: Props) {
  const {isExpanded, mode} = options;

  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      className={twMerge(expandStyles({isExpanded, mode}), className)}
      style={{width: size, height: size, ...style}}
    >
      <rect
        x="2"
        y="9"
        width="16"
        height="2"
        fill="currentColor"
        className={expandHorizontalStyles({isExpanded})}
      />
      <rect
        x="9"
        y="2"
        width="2"
        height="16"
        fill="currentColor"
        className={expandVerticalStyles({isExpanded})}
      />
    </svg>
  );
}
