import type {FormEvent} from 'react';
import {useCallback} from 'react';
import {ConsentAllStatus, normalizeFormData, useDuxState} from '@shopify/dux';
import type {PrivacySettings, Store, Track} from '@shopify/dux';

import {ClientEventSchema} from '../utils/dux/types';

export interface HandleFormSubmitOptions {
  trackers?: Track;
  store?: Store;
  event?: FormEvent<HTMLFormElement>;
  form?: ReturnType<typeof normalizeFormData>;
}

type HandleFormSubmitType = (options: HandleFormSubmitOptions) => void;

/**
 *
 * @param handleTrackingFn
 * @returns
 */
export const useFormTracking = (handleFormSubmitFn: HandleFormSubmitType) => {
  const {trackers, store} = useDuxState();
  const handleFormTracking = (event?: FormEvent<HTMLFormElement>) => {
    let form;
    if (event) {
      form = normalizeFormData(event);
    }
    handleFormSubmitFn({
      trackers,
      store,
      event: event || undefined,
      form,
    });
  };
  return handleFormTracking;
};

/**
 *
 * @param handleTrackingFn
 * @returns
 */
export const usePrivacyTracking = () => {
  const {trackers, store} = useDuxState();

  const handleTracking = useCallback(
    ({
      consentedAll,
      complianceZone,
      countryCode,
      url,
      apiVersion,
    }: PrivacySettings) => {
      if (store && trackers) {
        const {
          multiTrackToken = '',
          sessionToken = '',
          pageViewToken = '',
        } = store;
        trackers.dux({
          schemaId: ClientEventSchema.PrivacySignal,
          payload: {
            pageViewToken,
            multitrackToken: multiTrackToken,
            sessionToken,
            countryCode,
            complianceZone,
            url,
            consentedAll: consentedAll === ConsentAllStatus.Accepted,
            apiVersion,
            // TODO add regionCode for CCPA
            regionCode: '',
          },
        });
      }
    },
    [store, trackers],
  );
  return trackers ? handleTracking : undefined;
};
