import {useMatches} from '@remix-run/react';

import useHandle from '@/hooks/useHandle';
import {useTranslations} from '@/hooks/useTranslations';
import {useSiteData} from '@/hooks/useSiteData';
import {baseUri} from '@/constants';
import type {Image} from '@/utils/image';

const DEFAULT_FB_PAGES = '20409006880';
const DEFAULT_FB_APP_ID = '847460188612391';
const DEFAULT_SITE_NAME = 'Shopify';
const DEFAULT_OG_TYPE = 'website';
const DEFAULT_TWITTER_ID = '17136315';
const LARGE_TWITTER_CARD = 'summary_large_image';
export const DEFAULT_SHARE_IMAGE =
  'https://cdn.shopify.com/shopifycloud/brochure/assets/shared/social-image-default-large-57a90e6a8ae96f7277e9664ebfede1f143995ce7663119c832f9a7d4dd51f7e1.png';

interface SocialMetaProps {
  pageTitle: string;
  manualSocialShareImages?: boolean;
  metaDescription?: string;
}

export default function SocialMeta({
  pageTitle,
  manualSocialShareImages,
  metaDescription,
}: SocialMetaProps) {
  const {t, images, localeImage} = useTranslations();
  const {shareImage} = useHandle();
  const {site, canonicalUrl} = useSiteData();
  const matches = useMatches();

  let merged = {};
  for (const match of matches) {
    if (match.data) {
      merged = {...merged, ...match.data};
    }
  }

  const {
    shareImage: loaderShareImage,
    metaTitle: loaderMetaTitle,
    metaDescription: loaderMetaDescription,
  } = merged as any;

  let image = DEFAULT_SHARE_IMAGE;
  if (shareImage) {
    if (typeof shareImage === 'function') {
      image = shareImage(site || {});
    } else {
      image = shareImage;
    }
  } else if (images.shareImage) {
    if (typeof images.shareImage === 'string') {
      image = images.shareImage;
    } else {
      const share = localeImage<Image>(images.shareImage);
      if (share) {
        image = share;
      }
    }
  } else if (loaderShareImage) {
    image = loaderShareImage;
  }

  const url = canonicalUrl || baseUri;
  const metaTitle = t('htmlHead.shareTitle') || pageTitle;
  const description = metaDescription || t('htmlHead.metaDescription');

  return (
    <>
      <meta name="description" content={description} />
      <meta property="fb:pages" content={DEFAULT_FB_PAGES} />
      <meta property="fb:app_id" content={DEFAULT_FB_APP_ID} />
      <meta property="og:type" content={DEFAULT_OG_TYPE} />
      <meta property="og:site_name" content={DEFAULT_SITE_NAME} />
      <meta property="og:title" content={loaderMetaTitle || metaTitle} />
      <meta
        property="og:description"
        content={loaderMetaDescription || description}
      />
      {!manualSocialShareImages && (
        <>
          <meta property="og:image" content={loaderShareImage || image} />
          <meta property="twitter:image" content={loaderShareImage || image} />
        </>
      )}
      <meta property="og:url" content={url} />
      <meta property="twitter:card" content={LARGE_TWITTER_CARD} />
      <meta property="twitter:site" content={DEFAULT_SITE_NAME} />
      <meta property="twitter:account_id" content={DEFAULT_TWITTER_ID} />
      <meta property="twitter:title" content={loaderMetaTitle || metaTitle} />
      <meta
        property="twitter:description"
        content={loaderMetaDescription || description}
      />
    </>
  );
}
