export enum ClientEventSchema {
  Error = 'website_error_event/1.0',
  Form = 'website_form_event/2.0',
  PrivacySignal = 'website_privacy_signal/1.0',
}

export enum ServerEventSchema {
  CareersApplication = 'brochure3_careers_application_submit/2.0',
  UnifiedLeadForm = 'unified_lead_form_submit/1.3',
  UnifiedLeadForm1_4 = 'unified_lead_form_submit/1.4',
  UnifiedLeadForm1_9 = 'unified_lead_form_submit/1.9',
  UnifiedLeadForm1_10 = 'unified_lead_form_submit/1.10',
  UnifiedLeadForm1_11 = 'unified_lead_form_submit/1.11',
  MarketingUnsubscribe = 'marketing_email_unsubscribe/2.0',
  EnterpriseTcoCalculatorFormSubmit_1_0 = 'enterprise_tco_calculator_form_submit/1.0',
  BrochureToolReportGeneratorFormSubmit_1_0 = 'brochure_tool_report_generator_form_submit/1.0',
  StoresByShopifyFormSubmit_1_0 = 'stores_by_shopify_form_submit/1.0',
  MarketingEmailSubscribe_4_0 = 'marketing_email_subscribe/4.0',
  HelpCenterCustomerComplaintSubmit_1_0 = 'help_center_customer_complaint_submit/1.0',
}

export interface ServerEventExtraInfo {
  remoteIp?: string;
  userAgent?: string;
}

export interface ServerEventMetadata {
  event_created_at_ms?: number;
  event_sent_at_ms?: number;
  client_message_id?: string;
}

export interface MonorailMessage {
  schema_id: ClientEventSchema | ServerEventSchema;
  payload: any;
  metadata?: ServerEventMetadata;
  extra?: ServerEventExtraInfo;
}

export interface Monorail {
  enqueue(message: MonorailMessage): void;
  flush(): Promise<void>;
  produce(message: MonorailMessage): Promise<void>;
}

// temporary until ElementType exported as enum instead of type
export enum ElementType {
  ButtonClick = 'button_click',
  LinkClick = 'link_click',
  OutboundLinkClick = 'outbound_link_click',
  DropDownClick = 'dropdown_click',
  DropDownSelect = 'dropdown_select',
  InputSelect = 'input_select',
  ImageClick = 'image_click',
}
