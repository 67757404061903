import cn from 'classnames';
import {useEffect, useState, type FC} from 'react';
import {useDuxState} from '@shopify/dux';
import {ErrorBoundary} from 'react-error-boundary';

import {IconEnum} from '@/enums';
import {useTranslations} from '@/hooks/useTranslations';
import {ClientEventSchema} from '@/utils/dux/types';

import Icon from '../../elements/Icon/Icon';

export interface SectionErrorBoundaryProps {
  children?: React.ReactNode;
  fullWidth?: boolean;
  navbarPadding?: boolean;
}

export const SectionErrorBoundary: FC<SectionErrorBoundaryProps> = ({
  children,
  fullWidth,
  navbarPadding,
}) => {
  const {t} = useTranslations();
  const {trackers, store} = useDuxState();
  const [navbarHeight, setNavbarHeight] = useState(144);

  useEffect(() => {
    if (document) {
      const globalNav = document.querySelector(
        '[data-component-name="global-nav"]',
      );
      if (globalNav && globalNav instanceof HTMLElement) {
        setNavbarHeight(globalNav.offsetHeight);
      }
    }
  }, []);

  const logError = (error: Error) => {
    const sectionError = {
      message: error.message,
      stack: error.stack,
    };
    if (store && trackers) {
      const {pageViewToken = ''} = store;
      trackers.dux({
        schemaId: ClientEventSchema.Error,
        payload: {
          pageViewToken,
          message: JSON.stringify(sectionError),
        },
      });
    }
  };

  const fallback = (
    <div className={cn({container: !fullWidth})}>
      <div
        className="content-start flex flex-col items-center justify-center bg-shade-10 xl:h-180 md:h-160 h-100 p-auto"
        style={{
          paddingTop: navbarPadding ? navbarHeight : 'auto',
        }}
      >
        <Icon className="opacity-40" icon={IconEnum.Tools} size={40} />
        <p className="my-1 text-center px-gutter py-1">
          {t('global:sectionErrorBoundary.message')}
        </p>
      </div>
    </div>
  );

  return (
    <ErrorBoundary fallback={fallback} onError={logError}>
      {children}
    </ErrorBoundary>
  );
};
