import {IconEnum} from '@/enums';

import SvgIcon from './SvgIcon';
import svgIconMappings from './svgIconMappings';
import type {BaseIconProps} from './types';
import ChevronIcon, {type ChevronIconProps} from './icons/Chevron';
import ExpandCollapseIcon, {
  type ExpandCollapseIconProps,
} from './animated-icons/ExpandCollapse/ExpandCollapse';
import PlayPauseIcon, {
  type PlayPauseIconProps,
} from './animated-icons/PlayPause/PlayPause';

const DEFAULT_SIZE = 64;

export type IconProps =
  | BaseIconProps
  | ChevronIconProps
  | ExpandCollapseIconProps
  | PlayPauseIconProps;

export default function Icon({size = DEFAULT_SIZE, ...props}: IconProps) {
  const ariaHidden: boolean = !props['aria-label'];

  // SVG icon
  if (props.icon && props.icon in svgIconMappings) {
    return (
      <SvgIcon
        aria-hidden={ariaHidden}
        aria-label={props['aria-label']}
        className={props.className}
        icon={props.icon}
        size={size}
      />
    );
  }

  // Icons with bespoke options
  switch (props.icon) {
    case IconEnum.ExpandCollapse: {
      return (
        <ExpandCollapseIcon aria-hidden={ariaHidden} {...props} size={size} />
      );
    }

    case IconEnum.PlayPause: {
      return <PlayPauseIcon aria-hidden={ariaHidden} {...props} size={size} />;
    }

    case IconEnum.Chevron: {
      return <ChevronIcon aria-hidden={ariaHidden} {...props} size={size} />;
    }

    default:
      return null;
  }
}
