export default {
  100: '25rem', // 400px
  128: '32rem', // 512px
  160: '40rem', // 640px
  180: '45rem', // 720px
  /* Calc percentage width minus gutter */
  '1/2-gutter': 'calc((100% - (var(--gutter))) / 2)',
  '1/3-gutter': 'calc((100% - (var(--gutter) * 2)) / 3)',
  '1/4-gutter': 'calc((100% - (var(--gutter) * 3)) / 4)',
  '1/5-gutter': 'calc((100% - (var(--gutter) * 4)) / 5)',
  '1/6-gutter': 'calc((100% - (var(--gutter) * 5)) / 6)',
  gutter: 'var(--gutter)',
  margin: 'var(--margin)',
  /* Heights of the global header, including optional subnav */
  'legacy-global-header': '6rem',
  'legacy-global-header-with-border': 'calc(6rem + 1px)',
  'legacy-global-subnav': '4.25rem',
  'legacy-global-header-with-subnav': '10.25rem',
  'legacy-hero-with-subnav': '14.25rem',
  'global-header': 'var(--header-height)',
  'global-header-with-border': 'calc(var(--header-height) + 1px)',
  'global-subnav': 'var(--header-height)',
  'global-header-with-subnav': 'calc(var(--header-height) * 2)', //header height + subnav height = 9rem
  'hero-with-subnav': 'calc((var(--header-height) * 3) + 1px)', //header height + subnav height + hero-top + 1px border = 13.5 rem + 1px
  'hero-with-subnav-mobile': 'calc((var(--header-height) * 2) + 1px)', //header height + subnav height + 1px border = 9rem + 1px
  /* Standard sizes */
  xs: 'var(--space-xs)',
  sm: 'var(--space-sm)',
  md: 'var(--space-md)',
  lg: 'var(--space-lg)',
  xl: 'var(--space-xl)',
  '2xl': 'var(--space-2xl)',
  '3xl': 'var(--space-3xl)',
  '4xl': 'var(--space-4xl)',
  '5xl': 'var(--space-5xl)',
  'hero-top': 'var(--hero-top)',

  /* Component Spacing */
  /* ======================================== */
  'button-px': '1.25rem', // 20px
  'button-lg-px': '1.5rem', // 24px
  'button-py': '0.5rem', // 8px
  'button-lg-py': '0.75rem', // 12px

  /* Logo Marquee */
  'logo-marquee-y-3x': 'calc((var(--space-3xl) + 2rem) * 3)',
};
