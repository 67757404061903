import type {SVGProps} from 'react';
import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import type {IconEnum} from '@/enums';

const styles = cva('', {
  variants: {
    orientation: {
      up: '',
      down: '',
      left: '',
      right: '',
    },
  },
  compoundVariants: [
    {
      orientation: 'left',
      className: '-rotate-90',
    },
    {
      orientation: 'right',
      className: 'rotate-90',
    },
    {
      orientation: 'down',
      className: 'rotate-180',
    },
    {
      orientation: 'up',
      className: 'rotate-0',
    },
  ],
  defaultVariants: {
    orientation: 'up',
  },
});

export interface ChevronIconOptions {
  orientation?: 'up' | 'down' | 'left' | 'right';
}

export interface ChevronIconProps extends SVGProps<SVGSVGElement> {
  icon: IconEnum.Chevron;
  options?: ChevronIconOptions;
  size?: number;
  'aria-label'?: string;
}

type Props = Omit<ChevronIconProps, 'icon'>;

const Chevron = ({className, options, size, ...svgProps}: Props) => {
  const {orientation} = options ?? {};

  // Set width and height if size was provided
  if (size !== undefined) {
    svgProps.width = svgProps.height = size;
  }

  return (
    <svg
      fill="none"
      viewBox="0 0 16 16"
      aria-hidden="true"
      {...svgProps}
      className={twMerge(styles({orientation}), className)}
    >
      <path
        fill="currentColor"
        d="M12 10.4a.798.798 0 0 1-.566-.234L8 6.73l-3.434 3.435a.8.8 0 1 1-1.132-1.132l4-4a.8.8 0 0 1 1.132 0l4 4A.8.8 0 0 1 12 10.4Z"
      />
    </svg>
  );
};
export default Chevron;
