import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import type {IconEnum} from '@/enums';

import SvgPause from '../../icons/Pause';
import SvgPlay from '../../icons/Play';
import type {BaseIconProps} from '../../types';

const styles = cva(
  'absolute ease-out inset-0 transition-transform duration-200',
  {
    variants: {
      isActive: {
        true: 'scale-1 delay-200',
        false: 'scale-0',
      },
    },
  },
);

export interface PlayPauseIconOptions {
  isPlaying?: boolean;
}

export interface PlayPauseIconProps extends BaseIconProps {
  icon: IconEnum.PlayPause;
  options?: PlayPauseIconOptions;
  style?: React.CSSProperties;
}

type Props = Omit<PlayPauseIconProps, 'icon'>;

export default function PlayPauseIcon({
  className,
  options = {isPlaying: false},
  size,
  style,
}: Props) {
  const {isPlaying} = options;

  return (
    <div
      className={twMerge('relative', className)}
      style={{width: size, height: size, ...style}}
    >
      <div className={styles({isActive: isPlaying})}>
        <SvgPause />
      </div>
      <div className={styles({isActive: !isPlaying})}>
        <SvgPlay />
      </div>
    </div>
  );
}
