let idCounter = 0;

/**
 * Given a string, returns another string in camel case
 *
 * @method camelize
 * @param  {string} text
 * @return {string}
 */
export function camelize(text: string) {
  const string = (text || '').replace(/[-_\s.]+(.)?/g, (_, char) =>
    char ? char.toUpperCase() : '',
  );
  return string.substring(0, 1).toLowerCase() + string.substring(1);
}

/**
 * Decodes HTML-encoded entities in a string.
 *
 * @param {string} encodedString - The HTML-encoded string to decode.
 * @returns {string} - The decoded string.
 */
export function decodeHtmlEntities(encodedString: string) {
  const entities: {[key: string]: string} = {
    amp: '&',
    apos: "'",
    '#x27': "'",
    '#x2F': '/',
    '#39': "'",
    '#47': '/',
    lt: '<',
    gt: '>',
    nbsp: ' ',
    quot: '"',
  };

  return encodedString.replace(/&([^;]+);/g, (match, entity) => {
    if (entities.hasOwnProperty(entity)) {
      return entities[entity];
    }
    return match;
  });
}

/**
 * Given a string, returns another string in pascal case
 *
 * @method pascalize
 * @param  {string} text
 * @return {string}
 */
export function pascalize(text: string) {
  const string = (text || '').replace(/[-_\s./]+([A-Za-z])?/g, (_, char) =>
    char ? char.toUpperCase() : '',
  );
  return string.substring(0, 1).toUpperCase() + string.substring(1);
}

/**
 * Given a string, returns another string in snake case
 * Also converts camelCase and PascalCase
 *
 * @method snakeCase
 * @param  {string} text
 * @return {string}
 */
export function snakeCase(text: string): string {
  return text
    .replace(/\.?([A-Z]+)/g, (_x, y) => '_' + y.toLowerCase())
    .replace(/-/g, '_')
    .replace(/\s/g, '_')
    .replace(/^_/, '');
}

/**
 * Given a string with placeholders in the form of '{x}', '{y}', for example, replace those placeholders with the provided values.
 *
 * @method replacePlaceholders
 * @param  {string} phrase
 * @param  {...(string | number)} replacements
 * @return {string}
 */
export function replacePlaceholders(
  phrase: string,
  ...replacements: Array<string | number>
): string {
  let result: string = phrase;
  replacements.forEach((replacement: string | number) => {
    result = result.replace(/{[^}]+}/, String(replacement));
  });
  return decodeHtmlEntities(result);
}

/**
 * Given a string, returns another string in kebab case
 * Also converts camelcase
 *
 * @method kebab
 * @param  {string} text
 * @return {string}
 */
export function kebab(text: string) {
  const zhJaRegex =
    '[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]';

  const matches = text.match(
    new RegExp(
      `[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+|${zhJaRegex}+`,
      'gu',
    ),
  );

  return matches ? matches.map((x) => x.toLowerCase()).join('-') : '';
}

/**
 * Given a prefix, returns a unique string by incrementing an integer and
 * appending it to the prefix.
 *
 * @method uniqueId
 * @param  {string} prefix
 * @return {string}
 */
export function uniqueId(prefix: string) {
  ++idCounter;
  return `${prefix}${idCounter}`;
}

/**
 * Given a cookie name, returns the value of the cookie
 *
 * @param {string} cookieName - the name of the cookie
 * @return {string} cookie value or blank
 */
export function getCookie(cookieName: string) {
  const regexp = new RegExp(`(?:^|; )${cookieName}=([^;]*)(?:$|; )`);
  const match = document.cookie.match(regexp);
  return match ? decodeURIComponent(match[1]) : '';
}

/**
 * Extracts the URL from a `srcSet` string, prioritizing the '2x' descriptor if available, otherwise falling back to '1x'.
 *
 * @param {string} srcSet - The srcSet string containing URLs and descriptors.
 * @returns {string} - The URL corresponding to the '2x' descriptor if available, otherwise the '1x' URL.
 */
export function getSrcFromSrcSet(srcSet: string) {
  const srcSetArray = srcSet.split(',').map((src) => src.trim());

  let src1x = null;
  let src2x = null;

  srcSetArray.forEach((src) => {
    const [url, descriptor] = src.split(' ');
    if (descriptor === '2x' && url.startsWith('http')) {
      src2x = url;
    } else if (descriptor === '1x') {
      src1x = url;
    }
  });

  return src2x || src1x;
}

/**
 * Naive function which uses the userAgent to determine if the
 * browser is from a desktop or mobile device.
 *
 * @param {string} userAgent - the userAgent string
 * @return {'mobile' | 'desktop'}
 */
export function checkMobile(userAgent: string): 'mobile' | 'desktop' {
  let userAgentStr = userAgent;

  if (!userAgent && typeof window !== 'undefined') {
    userAgentStr = window.navigator.userAgent;
  }
  const regex = /(android|iphone|ipad|mobile|phone|mobi|blackberry)/i;

  return regex.test(userAgentStr) ? 'mobile' : 'desktop';
}
