import {useEffect} from 'react';

import {SIGNUP_BASE_URL} from '@/utils/signup/constants';
import {useSignupUrl} from '@/hooks/useSignupUrl';

// TODO review using url util on link creation
export default function SignupClickHandler() {
  const {getSignupURL} = useSignupUrl();

  useEffect(() => {
    document.addEventListener(`contextmenu`, (event: MouseEvent) => {
      const link: EventTarget | null = event.target;
      if (link instanceof HTMLAnchorElement) {
        const linkHref = link.getAttribute(`href`);
        if (linkHref && linkHref.startsWith(SIGNUP_BASE_URL)) {
          const signUpURL = getSignupURL({url: linkHref}).toString();
          link.setAttribute(`href`, signUpURL);
        }
      }
    });

    document.addEventListener(`click`, (event: MouseEvent) => {
      const link: any = event.target;
      const linkHref = link.getAttribute(`href`);
      const newTab = link.getAttribute(`target`) === `_blank`;

      if (linkHref && linkHref.startsWith(SIGNUP_BASE_URL)) {
        event.preventDefault();
        const signUpURL = getSignupURL({url: linkHref}).toString();

        if (newTab) {
          window.open(signUpURL, `_blank`);
        } else {
          window.location.href = signUpURL;
        }
      }
    });

    document.addEventListener(`auxclick`, (event: MouseEvent) => {
      const link: any = event.target;
      const linkHref = link.getAttribute(`href`);

      if (linkHref && linkHref.startsWith(SIGNUP_BASE_URL)) {
        event.preventDefault();
        const signUpURL = getSignupURL({url: linkHref}).toString();
        window.open(signUpURL, `_blank`);
      }
    });
  }, [getSignupURL]);

  return null;
}
