export default {
  light:
    '0px 8px 48px rgba(24, 24, 27, 0.08), 0px 4px 8px rgba(24, 24, 27, 0.04), 0px 0px 2px rgba(24, 24, 27, 0.25)',
  dark: '0px 8px 48px rgba(24, 24, 27, 0.24), 0px 4px 8px rgba(24, 24, 27, 0.16), 0px 0px 2px rgba(255, 255, 255, 0.52)',
  'soft-2xl':
    '0px 8px 48px rgba(0, 0, 0, 0.08), 0px 4px 8px rgba(0, 0, 0, 0.08), 0px 0px 1px rgba(0, 0, 0, 0.4)',
  'soft-dark-2xl':
    '0px 8px 48px #0F1214, 0px 4px 8px rgba(66, 71, 76, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.9)',
  // homepage
  'globe-product-card':
    '0px 8px 48px #EEEEEE, 0px 4px 8px rgba(66, 71, 76, 0.06), 0px 0px 1px rgba(66, 71, 76, 0.48)',
  'homepage-card':
    '0px 4px 8px rgba(66, 71, 76, 0.06), 0px 0px 1px rgba(66, 71, 76, 0.48)',
  'products-autosuggest':
    '0 8px 48px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.08), 0 0 1px rgba(0, 0, 0, 0.4)',
  'checkout-demo-screen': '0px 10px 40px -15px rgba(0, 0, 0, 1)',
  // /collective/retailer
  'collective-hero': 'inset 0 -40px 40px -16px',
  'collective-section-2': 'inset 0 60px -70px -16px',
  'collective-section-2-md': 'inset 0px 40px 30px -16px',
  // customer-marketing-tools
  'marketing-tools-merchant-profile-nav':
    'inset 0px 1px 1px rgba(77, 50, 38, 0.5)',
  'customer-marketing-tools-mobile-frame': '0px 12px 20px 0px #0000002E',
  'customer-marketing-tools-next-button':
    '0px 4px 18px -2px rgba(31, 33, 36, 0.08), 0px 12px 18px -2px rgba(31, 33, 36, 0.15);',
  // AppsShowcase
  'apps-showcase-tooltip-shadow': [
    '0px 6px 4px -2px rgba(16, 24, 40, 0.03)',
    '0px 16px 12px -4px rgba(16, 24, 40, 0.08)',
    '0px 0px 1px 0px rgba(16, 24, 40, 0.54)',
  ],
  'apps-showcase-item-shadow': ['0 0 4px 1px rgb(0, 0, 0, 0.1)'],
  'apps-showcase-item-shadow-hover': [
    '0 5px 15px 3px rgba(0, 0, 0, 0.1)',
    '0 4px 6px -4px rgba(0, 0, 0, 0.1)',
  ],
};
