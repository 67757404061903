import {useTranslations} from '@/hooks/useTranslations';

export default function PageTitle({
  title,
  isPureTitle,
}: {
  title: string;
  isPureTitle?: boolean;
}) {
  const {t, site} = useTranslations();

  if (isPureTitle) {
    return <title>{`${title}`}</title>;
  }

  let countryCode;
  if (site?.domain === 'zh.shopify.com') {
    countryCode = 'CN';
  } else if (site?.countryCode === 'US') {
    // There is no US key in global.countries,
    // the key is global.countries.en instead
    countryCode = 'EN';
  } else {
    countryCode = site?.countryCode || 'EN';
  }

  const brand = t('global:shopify', {defaultValue: 'Shopify'});
  const country = t(`global:countries.${countryCode.toLowerCase()}`) || '';
  if (!(brand || country)) {
    return <title>{title}</title>;
  }

  const noCountry = !title.toLowerCase().includes(country.toLowerCase());
  const isNotUsLocale = site?.countryCode !== 'US';

  let append = '';
  // Shopify at the very end, and it is not US locale
  if (title.match(/(shopify\s*)$/i)) {
    if (noCountry && isNotUsLocale) {
      append = ` ${country}`;
    }
    // Shopify at the very end with year, and it is not US locale
  } else if (title.match(/(shopify\s*)(\(?\d{4}\)?\s*$)/i)) {
    if (noCountry && isNotUsLocale) {
      append = ` ${country}`;
    }
    // No country, Shopify not at the end
  } else if (noCountry) {
    append = ` - ${brand}`;
    // we should not add "- USA" if currant locale is US
    if (isNotUsLocale) {
      append += ` ${country}`;
    }

    // Already has "Shopify [country]" somewhere
  } else if (title.match(new RegExp(`(shopify ${country})`, 'i'))) {
    append = '';
    // Has country, Shopify not at the end
  } else {
    append = ` - ${brand}`;
  }

  return <title>{`${title}${append}`}</title>;
}
