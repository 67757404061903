// These use CSS variables because the partials "theme" resets
// the font sizes for the Rails side
export default {
  'body-sm': [
    'var(--font-size-body-sm)',
    {
      lineHeight: 'var(--line-height-body-sm)',
      letterSpacing: 'var(--letter-spacing-body-sm)',
    },
  ],
  'body-base': [
    'var(--font-size-body-base)',
    {
      lineHeight: 'var(--line-height-body-base)',
      letterSpacing: 'var(--letter-spacing-body-base)',
    },
  ],
  'body-lg': [
    'var(--font-size-body-lg)',
    {
      lineHeight: 'var(--line-height-body-lg)',
      letterSpacing: 'var(--letter-spacing-body-lg)',
    },
  ],

  // Title sizes
  t8: [
    'var(--font-size-t8)',
    {
      fontWeight: 'var(--font-weight-t8)',
      lineHeight: 'var(--line-height-t8)',
      letterSpacing: 'var(--letter-spacing-t8)',
    },
  ],
  t7: [
    'var(--font-size-t7)',
    {
      fontWeight: 'var(--font-weight-t7)',
      lineHeight: 'var(--line-height-t7)',
      letterSpacing: 'var(--letter-spacing-t7)',
    },
  ],
  t6: [
    'var(--font-size-t6)',
    {
      fontWeight: 'var(--font-weight-t6)',
      lineHeight: 'var(--line-height-t6)',
      letterSpacing: 'var(--letter-spacing-t6)',
    },
  ],
  t5: [
    'var(--font-size-t5)',
    {
      fontWeight: 'var(--font-weight-t5)',
      lineHeight: 'var(--line-height-t5)',
      letterSpacing: 'var(--letter-spacing-t5)',
    },
  ],
  t4: [
    'var(--font-size-t4)',
    {
      fontWeight: 'var(--font-weight-t4)',
      lineHeight: 'var(--line-height-t4)',
      letterSpacing: 'var(--letter-spacing-t4)',
    },
  ],
  t3: [
    'var(--font-size-t3)',
    {
      fontWeight: 'var(--font-weight-t3)',
      lineHeight: 'var(--line-height-t3)',
      letterSpacing: 'var(--letter-spacing-t3)',
    },
  ],
  t2: [
    'var(--font-size-t2)',
    {
      fontWeight: 'var(--font-weight-t2)',
      lineHeight: 'var(--line-height-t2)',
      letterSpacing: 'var(--letter-spacing-t2)',
    },
  ],
  t1: [
    'var(--font-size-t1)',
    {
      fontWeight: 'var(--font-weight-t1)',
      lineHeight: 'var(--line-height-t1)',
      letterSpacing: 'var(--letter-spacing-t1)',
    },
  ],
  dsp: [
    'var(--font-size-dsp)',
    {
      fontWeight: 'var(--font-weight-dsp)',
      lineHeight: 'var(--line-height-dsp)',
      letterSpacing: 'var(--letter-spacing-dsp)',
    },
  ],
  '4xl': ['2.5rem', '2.75rem'],

  /* Component Font Sizes */
  /* ======================================== */
  'button-size': [
    '1rem',
    {
      lineHeight: '1.5rem',
      letterSpacing: '-.01em',
    },
  ],
  'button-lg-size': [
    '1.125rem',
    {
      lineHeight: '1.75rem',
      letterSpacing: '-.01em',
    },
  ],
};
